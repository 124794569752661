/* REVENUES */
export const GET_REVENUES = "GET_REVENUES"
export const GET_REVENUES_SUCCESS = "GET_REVENUES_SUCCESS"
export const GET_REVENUES_FAIL = "GET_REVENUES_FAIL"

// REVENUE DETAILS
export const GET_REVENUE_DETAILS = "GET_REVENUE_DETAILS"
export const GET_REVENUE_DETAILS_SUCCESS = "GET_REVENUE_DETAILS_SUCCESS"
export const GET_REVENUE_DETAILS_FAIL = "GET_REVENUE_DETAILS_FAIL"

export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS"
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS"
export const GET_ALL_TRANSACTIONS_FAIL = "GET_ALL_TRANSACTIONS_FAIL"
