import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
} from "reactstrap"
import { Col, Container, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import MetaTag from "components/Common/Meta-tag"

import classnames from "classnames"
import UserAddress from "./components/UserAddress"
import UserDropDown from "./components/UserDropDown"
import AddProduct from "./components/AddProduct"
import { createUserOrders } from "store/actions"
import { useHistory } from "react-router-dom"
import AddressModal from "./components/AddressModal"
import CreateUserModal from "./components/CreateUserModal"

const CreateAdminOrder = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading } = useSelector(state => ({
    loading: state.Collection.loading,
  }))

  const [selectedUserId, setSelectedUserId] = useState()
  const [selectAddress, setSelectAddress] = useState()
  const [paymentType, setPaymentType] = useState("Online Payment")

  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  function handleValidSubmit(values) {
    const finalData = {
      userId: selectedUserId,
      shippingInfo: selectAddress?.shippingInfo,
      paymentType: paymentType,
    }
    dispatch(createUserOrders(finalData, history))
    history.push("/orders")
  }

  const [addressModal, setAddressModal] = useState(false)
  const [updateAddress, setupdateAddress] = useState()

  const handleAddressModal = adress => {
    console.log(adress)
    setAddressModal(true)
    setupdateAddress(adress)
  }
  const [userPopup, setUserPopup] = useState({ status: false, data: {} })

  return (
    <>
      <CreateUserModal
        show={userPopup.status}
        onCloseClick={() => setUserPopup({ status: false })}
      />
      <AddressModal
        show={addressModal}
        onCloseClick={() => setAddressModal(false)}
        userId={selectedUserId}
        updatedAdress={updateAddress}
      />
      <MetaTag title="Create Order" />

      <div className="page-content">
        <Breadcrumbs title="Orders" breadcrumbItem="Create Order" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Fill the form</h4>
                      <div className="vertical-wizard wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul className="steps-new">
                            <NavItem
                              className={classnames({
                                current: activeTabVartical === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTabVartical === 1,
                                  isActive: (
                                    passedStepsVertical || []
                                  ).includes(1),
                                })}
                                onClick={() => {
                                  toggleTabVertical(1)
                                }}
                                disabled={
                                  !(passedStepsVertical || []).includes(1)
                                }
                              >
                                Select User
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTabVartical === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTabVartical === 2,
                                  isActive: (
                                    passedStepsVertical || []
                                  ).includes(2),
                                })}
                                onClick={() => {
                                  toggleTabVertical(2)
                                }}
                                disabled={
                                  !(passedStepsVertical || []).includes(2)
                                }
                              >
                                <span>Shipping Address</span>
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTabVartical === 3,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTabVartical === 3,
                                  isActive: (
                                    passedStepsVertical || []
                                  ).includes(3),
                                })}
                                onClick={() => {
                                  toggleTabVertical(3)
                                }}
                                disabled={
                                  !(passedStepsVertical || []).includes(3)
                                }
                              >
                                Add Product
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTabVartical === 4,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTabVartical === 4,
                                  isActive: (
                                    passedStepsVertical || []
                                  ).includes(4),
                                })}
                                onClick={() => {
                                  toggleTabVertical(4)
                                }}
                                disabled={
                                  !(passedStepsVertical || []).includes(4)
                                }
                              >
                                Confirm Order
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix pt-0 md:pt-20">
                          <TabContent
                            activeTab={activeTabVartical}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <UserDropDown
                                userId={selectedUserId}
                                setSelectedUserId={setSelectedUserId}
                                setUserPopup={setUserPopup}
                              />
                            </TabPane>

                            <TabPane tabId={2}>
                              <UserAddress
                                userId={selectedUserId}
                                setSelectAddress={setSelectAddress}
                                selectAddress={selectAddress}
                                handleAddressModal={handleAddressModal}
                              />
                            </TabPane>

                            <TabPane tabId={3}>
                              <AddProduct
                                paymentType={paymentType}
                                setPaymentType={setPaymentType}
                                userId={selectedUserId}
                              />
                            </TabPane>

                            <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Order Confirm</h5>
                                      <p className="text-muted">
                                        If several languages coalesce, the
                                        grammar of the resulting
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>

                        <div className="actions clearfix navigation-btn mt-4 me-4">
                          <ul>
                            {activeTabVartical === 4 && (
                              <>
                                <li
                                  className={"previous"}
                                  onClick={() => {
                                    toggleTabVertical(1)
                                  }}
                                >
                                  <span>Add New Order</span>
                                </li>
                                <li
                                  className={"previous bg-success text-white"}
                                  onClick={() => history.push("/orders")}
                                >
                                  <span>All Orders</span>
                                </li>
                              </>
                            )}

                            {activeTabVartical !== 4 && (
                              <li
                                className={
                                  activeTabVartical === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                <span>Previous</span>
                              </li>
                            )}

                            {activeTabVartical === 3 && (
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                                style={{ minWidth: "200px" }}
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                Create Order
                              </button>
                            )}

                            {activeTabVartical < 3 && (
                              <>
                                {activeTabVartical === 1 ? (
                                  <>
                                    {isEmpty(selectedUserId) ? (
                                      <li className={"next disabled"}>
                                        <span>Next</span>
                                      </li>
                                    ) : (
                                      <li
                                        className={"next"}
                                        onClick={() => {
                                          toggleTabVertical(
                                            activeTabVartical + 1
                                          )
                                        }}
                                      >
                                        <span>Next</span>
                                      </li>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isEmpty(selectAddress?.shippingInfo) ? (
                                      <li className={"next disabled"}>
                                        <span>Next</span>
                                      </li>
                                    ) : (
                                      <li
                                        className={"next"}
                                        onClick={() => {
                                          toggleTabVertical(
                                            activeTabVartical + 1
                                          )
                                        }}
                                      >
                                        <span>Next</span>
                                      </li>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateAdminOrder
