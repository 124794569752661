import React, { useState } from "react"
import { Row, Col, Spinner, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { HiMinus, HiPlus } from "react-icons/hi"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "../../../assets/scss/datatables.scss"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import { Link } from "react-router-dom"
import { CurrencySwitch } from "hooks/Currency"
import { addItemToUserCart } from "store/actions"
import PropTypes from "prop-types"
import { defaultImg } from "assets/images"

const OrderItemsDatatable = ({ userId, isUpdate }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { userCartDetails, loading, orderDetails } = useSelector(state => ({
    userCartDetails: state.Users.userCartDetails,
    loading: state.Users.loading,
    orderDetails: state.Orders.orderDetails,
  }))
  console.log(orderDetails)

  const getTotalId = () => {
    if (isUpdate && orderDetails?._id) {
      return userCartDetails?.cartItems

      return orderDetails?.orderItems?.map(item => ({
        ...item,
        product: {
          _id: item.product,
          images: [{ url: item?.imageUrl }],
          primaryLang: item?.primaryLang,
        },
      }))
    } else {
      return userCartDetails?.cartItems
    }
  }

  console.log("getTotalId", getTotalId())
  // const handle=  orderDetails: state.Orders.orderDetails,

  const total = getTotalId()?.length

  const currency = CurrencySwitch()

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const PreColumns = [
    {
      dataField: "no",
      text: "No.",
    },
    {
      dataField: "imageData",
      text: "Image",
    },
    {
      dataField: "productName",
      text: "Name",
    },
    {
      dataField: "product.secondaryLang.name",
      text: "Secondary Name",
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    // {
    //   dataField: "quantity",
    //   text: "Qty",
    //   sort: true,
    // },
    {
      dataField: "itemTotal",
      text: "Total",
    },
    {
      dataField: "action",
      text: "",
    },
  ]

  const handleQuantityChange = (item, type) => {
    const cartItem = {
      product: item?.product?._id,
      operation: type,
    }
    dispatch(addItemToUserCart(userId, cartItem))
  }

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const userCartData = map(getTotalId(), (item, index) => ({
    ...item,
    no: (pageSend() - 1) * 10 + index + 1,
    imageData: (
      <Link to={`/product/${item?.product?._id}`}>
        <img
          className="header-profile-user"
          style={{ width: "70px", height: "70px" }}
          src={
            item?.product?.images ? item?.product?.images[0]?.url : defaultImg
          }
        />
      </Link>
    ),
    productName: (
      <Link to={`/product/${item?.product?._id}`} className="text-muted">
        <p className="p-0 m-0 w-100" style={{ whiteSpace: "break-spaces" }}>
          {item?.product?.primaryLang?.name}{" "}
          {`${
            item?.varientValue
              ? "( " + item?.varientValue + " " + item?.varientUnit + ")"
              : ""
          }`}
        </p>
      </Link>
    ),
    price: (
      <span>
        {currency} {item?.price}
      </span>
    ),
    itemTotal: (
      <div
        className="d-flex align-items-center justify-content-between pb-0 mb-0"
        style={{ height: 50 }}
      >
        <div className="guest_counter_wrapp">
          <p
            className="text-center mb-1 "
            style={{ fontWeight: "600", fontSize: "14px" }}
          >
            {currency} {item?.price * item?.quantity}
          </p>
          <div className="guest_counter">
            <span
              className="guest_counter_icon minus"
              onClick={() => handleQuantityChange(item, "substract")}
            >
              <HiMinus />
            </span>
            <span className="guest_count">{item?.quantity}</span>
            <span
              // className="guest_counter_icon plus text-muted"
              // style={{ backgroundColor: "lightgray" }}
              className="guest_counter_icon plus"
              onClick={() => handleQuantityChange(item, "add")}
            >
              <HiPlus />
            </span>
          </div>
        </div>
      </div>
    ),
    // itemTotal: (
    //   <span>
    //     {currency} {item?.price * item?.quantity}
    //   </span>
    // ),
    action: (
      <Button
        id={`Delete${index}`}
        type="button"
        color="white"
        className="ms-1 btn "
        onClick={() => {
          handleCartItemDelete(item)
        }}
      >
        <i
          className="bx bx-trash me-2"
          style={{ color: "red", fontSize: "20px" }}
        />
      </Button>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const handleCartItemDelete = item => {
    const cartItem = {
      product: item?.product?._id,
      // varient:
      //   selectedProduct?.hasVarients === true
      //     ? selectedProduct?.varients[0]?._id
      //     : "",
      quantity: item?.quantity ? item?.quantity : 1,
      operation: "substract",
    }
    dispatch(addItemToUserCart(userId, cartItem))
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userCartData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap orderItemTable"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/*        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />*/}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrderItemsDatatable

OrderItemsDatatable.propTypes = {
  userId: PropTypes.string,
  isUpdate: PropTypes.bool,
}
